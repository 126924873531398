import Vue from 'vue'
import AccountTypes from '@/common/enums/accountTypeEnum'

export default {
  namespaced: true,
  state: {
    venue: {},
    venueObject: {},
    venueTypeList: [],
  },
  mutations: {
    SET_VENUE(state, payload) {
      state.venue = { ...payload }
    },
    SET_VENUE_TYPE_LIST(state, payload) {
      state.venueTypeList = [...payload]
    },
    SET_VENUE_OBJECT(state, payload) {
      state.venueObject = { ...payload }
    },
  },
  actions: {
    getUserRole({ rootGetters }) {
      return rootGetters['user/getUser'].user_has_role[0].role.role
    },
    async getAllVenue({ commit, dispatch }, {
      offset, limit, searchQuery, userHasGroupId,
    }) {
      const userRole = await dispatch('getUserRole')
      if (userRole === AccountTypes.ADMIN) {
        try {
          const response = await Vue.prototype.$axios.get('/admin/venue', {
            params: {
              offset: offset - 1,
              limit,
              search_query: searchQuery,
            },
            headers: {
              userHasGroupId,
            },
          })
          if (response.data.statusCode === 200) {
            const { data } = response
            commit('SET_VENUE', data)
          }
          return true
        } catch (e) {
          console.log(e)
          throw new Error(e)
        }
      } else {
        try {
          const response = await Vue.prototype.$axios.get('/user/venue', {
            params: {
              offset: offset - 1,
              limit,
              search_query: searchQuery,
            },
          })
          if (response.data.statusCode === 200) {
            const { data } = response
            commit('SET_VENUE', data)
          }
          return true
        } catch (e) {
          console.log(e)
          throw new Error(e)
        }
      }
    },
    async getVenueTypeList({ commit }) {
      try {
        const response = await Vue.prototype.$axios.get('/venue-type')
        if (response.data.statusCode === 200) {
          const { data } = response
          commit('SET_VENUE_TYPE_LIST', data.data)
        }
        return true
      } catch (e) {
        console.log(e)
        throw new Error(e)
      }
    },
    async createVenue({ commit, dispatch }, { payload, userHasGroupId }) {
      const userRole = await dispatch('getUserRole')
      if (userRole === AccountTypes.ADMIN) {
        try {
          const response = await Vue.prototype.$axios.post('/admin/venue', payload, {
            headers: {
              userHasGroupId,
            },
          })
          if (response.data.statusCode === 201) {
            const { data: { data } } = response || {}
            commit('SET_VENUE_OBJECT', data)
          }
          return true
        } catch (e) {
          console.log(e)
          throw new Error()
        }
      } else {
        try {
          const response = await Vue.prototype.$axios.post('/user/venue', payload)
          if (response.data.statusCode === 201) {
            const { data: { data } } = response || {}
            commit('SET_VENUE_OBJECT', data)
          }
          return true
        } catch (e) {
          console.log(e)
          throw new Error()
        }
      }
    },
  },
  getters: {
    getVenue: state => state.venue,
    getVenueTypeList: state => state.venueTypeList,
    getVenueObject: state => state.venueObject,
  },
}
