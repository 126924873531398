export default [
  {
    path: '/support',
    name: 'support',
    component: () => import('@/views/support/index.vue'),
    meta: {
      pageTitle: 'Support',
      breadcrumb: [
        {
          text: 'Support',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
]
