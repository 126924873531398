export default [
  {
    path: '/devices/:venueId',
    name: 'devices',
    component: () => import('@/views/devices/index.vue'),
    meta: {
      pageTitle: 'Screens',
      breadcrumb: [
        {
          text: 'Venues',
          to: '/venues',
        },
        {
          text: 'Screens',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/device/:id/:deviceName/:venueId',
    name: 'single-device-user',
    component: () => import('@/views/devices/SingleDevice.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [

        {
          text: 'Screens',
          to: '/devices/:venueId',
          isDynamic: true,
          key: 'venueId',
        },
        {
          text: '',
          active: true,
        },
      ],
      requireAuth: true,
    },
    beforeEnter(to, from, next) {
      if (to.name === 'single-device-user') {
      /* eslint-disable */
        to.meta.pageTitle = to.params.deviceName
        to.meta.breadcrumb[1].text=to.params.deviceName
        /* eslint-enable */
        next()
      }
    },
  },
  {
    path: '/add-device',
    name: 'create-device',
    component: () => import('@/components/devices-group/CreateDevice.vue'),
    meta: {
      pageTitle: 'Add Screen',
      breadcrumb: [
        {
          text: 'Venues',
          to: '/venues',
        },
        {
          text: 'Add screen',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
]
