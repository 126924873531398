export default [
  {
    path: '/screenList',
    name: 'screenList',
    component: () => import('@/views/admin/screenList/index.vue'),
    meta: {
      pageTitle: 'All Screens',
      breadcrumb: [
        {
          text: 'All Screens',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
]
