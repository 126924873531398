import Vue from 'vue'
import AccountTypes from '@/common/enums/accountTypeEnum'
import ApplicationTypeEnum from '@/common/enums/applicationTypeEnum'

export default {
  namespaced: true,
  state: {
    userIP: null,
    currentUser: {
      date_of_birth: '0000-00-00',
    },
    userGroups: [],
    userGroupsFetched: false,
    userRole: '',
  },
  mutations: {
    SET_USER(state, payload) {
      state.currentUser = payload
      state.userRole = payload.user_has_role[0].role.role
    },
    SET_USER_GROUPS(state, payload) {
      state.userGroups = []
      state.userGroups = payload
    },
    SET_GROUPS_FETCHED(state, payload) {
      state.userGroupsFetched = payload
    },
    CLEAR_USER_STORE(state) {
      state.currentUser = {
        date_of_birth: '0000-00-00',
      }
      state.userGroups = []
    },
    SET_USER_IP(state, payload) {
      state.userIP = payload
    },
  },
  actions: {
    setUser: ({ commit }, user) => {
      commit('SET_USER', user)
    },
    setSelectedGroupId: ({ commit }, id) => {
      commit('SET_SELECTED_GROUP_ID', id)
    },
    async getAllUserGroupsIfNotPresent({ commit, state, getters }, forceFetch = false) {
      const { isUserAdmin } = getters
      if ((forceFetch || !state.userGroups.length) && !isUserAdmin) {
        let userGroups = []
        commit('SET_GROUPS_FETCHED', false)
        try {
          const { data: { data } } = await Vue.prototype.$axios
            .get('user-has-group/get-user-groups')
          userGroups = data
        } catch (e) {
          //
        } finally {
          commit('SET_GROUPS_FETCHED', true)
        }
        const groupsForLauncher = userGroups.filter(group => {
          let applicationExist = false
          if (group.permissions && group.permissions.length) {
            group.permissions.forEach(permission => {
              if (permission.feature && permission.feature.application_type === ApplicationTypeEnum.SIMI_LAUNCHER) {
                applicationExist = true
              }
            })
          }
          return applicationExist
        })
        commit('SET_USER_GROUPS', groupsForLauncher)
      }
    },
    async getUserIfNotExist({ commit, state }, forceFetch = false) {
      if (forceFetch || !state.currentUser.id) {
        try {
          const { data: { data } } = await Vue.prototype.$axios.get('user')
          let isAdmin = false
          if (data && data.user_has_role && data.user_has_role.length) {
            isAdmin = !!data.user_has_role.find(object => object.role.role === AccountTypes.ADMIN)
          }
          if (!isAdmin && !data.is_launcher_access) {
            throw new Error('No user group found for Simmi Launcher, logging out...')
          }
          commit('SET_USER', data)
          // console.log(data)
        } catch (e) {
          commit('CLEAR_USER_STORE')
          throw new Error('User not have access to this portal, logging out...')
        }
      }
    },
    async logoutUserFromOtherApp() {
      try {
        await window.open(`${process.env.VUE_APP_SIMI_CONNECT_BASE_URL}?logout=true`, 'launcherConnect')
        await window.open(`${process.env.VUE_APP_SIMI_IOT_BASE_URL}?logout=true`, 'LauncherIOT')
      } catch (e) {
        // console.error(e)
      }
    },
    async logoutUserWithRedirect() {
      await Vue.prototype.$auth.logout({ returnTo: `${window.location.origin}/logout` })
    },
    async logoutUserLocalOnly({ commit }) {
      await Vue.prototype.$auth.logout({ localOnly: true })
      commit('CLEAR_USER_STORE')
    },
  },
  getters: {
    getUser: state => state.currentUser,
    getUserRole: state => state.userRole,
    getAllUserGroups: state => state.userGroups,
    isGroupsFetched: state => state.userGroupsFetched,
    getSelectedGroupImage(state, getters) {
      return getters.getSelectedGroupMember.user_has_group ? getters.getSelectedGroupMember.user_has_group.image : null
    },
    getSelectedGroupMember(state, getters, rootState, rootGetters) {
      const group = state.userGroups.find(res => res.user_has_group.id === rootGetters['persistedStore/getSelectedGroupId'])
      if (group) {
        return group
      } return {}
    },
    getSelectedGroupName(state, getters) {
      return getters.getSelectedGroupMember.user_has_group ? getters.getSelectedGroupMember.user_has_group.name : null
    },
    getSelectedGroupMemberPermissions(state, getters) {
      return getters.getSelectedGroupMember.permissionsList ? getters.getSelectedGroupMember.permissionsList : []
    },
    getSelectedGroupMemberStatus(state, getters) {
      return getters.getSelectedGroupMember.user_has_group && getters.getSelectedGroupMember.user_has_group.package && getters.getSelectedGroupMember.user_has_group.package.isActive ? getters.getSelectedGroupMember.user_has_group.package.isActive : false
    },
    getPermissionOfMember: (state, getters) => payload => getters.getSelectedGroupMemberPermissions.includes(payload),
    isUserAdmin(state) {
      let isAdmin = false
      if (state.currentUser && state.currentUser.user_has_role && state.currentUser.user_has_role.length) {
        // eslint-disable-next-line no-restricted-syntax
        for (const userHasRoleElement of state.currentUser.user_has_role) {
          if (userHasRoleElement.role && userHasRoleElement.role.role === AccountTypes.ADMIN) {
            isAdmin = true
            break
          }
        }
      }
      return isAdmin
    },
    isSimmiUser(state) {
      let isSimmiUser = false
      if (state.currentUser && state.currentUser.user_has_role && state.currentUser.user_has_role.length) {
        // eslint-disable-next-line no-restricted-syntax
        for (const userHasRoleElement of state.currentUser.user_has_role) {
          if (userHasRoleElement.role && userHasRoleElement.role.role === AccountTypes.USER) {
            isSimmiUser = true
            break
          }
        }
      }
      return isSimmiUser
    },
    getUserIP: state => state.userIP,
  },
}
