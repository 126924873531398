import customViews from '@/router/pages/admin/customViews'
import users from '@/router/pages/admin/users'
import nonConfiguredScreen from '@/router/pages/admin/nonconfigured'
import businessTrial from '@/router/pages/admin/businessTrial'
import screenList from '@/router/pages/admin/screenList'

const adminRoutes = [
  ...users,
  ...customViews,
  ...nonConfiguredScreen,
  ...businessTrial,
  ...screenList,
]

export {
  // eslint-disable-next-line import/prefer-default-export
  adminRoutes,
}
