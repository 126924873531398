import Vue from 'vue'
import VueCarousel from 'vue-carousel'
import { ToastPlugin, ModalPlugin, IconsPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueSweetalert2 from 'vue-sweetalert2'
import VueVideoPlayer from 'vue-video-player'
import VueCookies from 'vue-cookies'
import moment from 'moment'
import GmapVue from 'gmap-vue'
import vueTabevents from 'vue-tabevents'
import axios from './auth/axios'
import router from './router'
import store from './store'
import App from './App.vue'
import 'vuesax/dist/vuesax.css'
// Global Components
import './global-components'
// Import the Auth0 configuration
// import { domain, clientId } from '../auth_config.json'
import { domain, clientId } from '../auth_config.json'
// require videojs style
// eslint-disable-next-line import/no-extraneous-dependencies
import 'sweetalert2/dist/sweetalert2.min.css'
// 3rd party plugins
import '@/libs/vue-select'
import '@/libs/portal-vue'
import '@/libs/toastification'
// import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-it-bigger/dist/vue-it-bigger.min.css'
import { Auth0Plugin } from '@/auth/auth0'

Vue.use(vueTabevents)

Vue.use(moment)
Vue.use(VueCookies)
Vue.use(VueCarousel)
Vue.use(IconsPlugin)
Vue.use(
  VueVideoPlayer, /* {
    options: global default options,
    events: global videojs events
  } */
)
Vue.prototype.$axios = axios
// Vue.prototype.$auth = auth
function loadGooglePlacesAPI() {
  const script = document.createElement('script')
  script.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${process.env.VUE_APP_GOOGLE_PLACES_API_KEY}&callback=initAutocomplete`
  script.async = true
  script.defer = true
  document.head.appendChild(script)

  script.onload = () => {
    console.log('Google Places API loaded successfully')
    // Initialize your Google Places related functionality here
  }

  script.onerror = () => {
    console.error('Failed to load the Google Places API')
  }
}

Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyDjAZTbfd38nqpjt_ugSytfzrLDUBlXfcE',
    libraries: 'drawing,places,geometry',
  },
  installComponents: true,
})
// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    )
  },
})
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.component('GoogleMap', GmapVue.Map)
Vue.component('GoogleMarker', GmapVue.Marker)
// Composition API
Vue.use(VueCompositionAPI)
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.use(VueSweetalert2)

Vue.config.productionTip = false
const app = null

// auth.initApp(Vue, app, router, store, App)
new Vue({
  Vue,
  app,
  router,
  store,
  App,
  render: h => h(App),
}).$mount('#app')

document.addEventListener('DOMContentLoaded', event => {
  loadGooglePlacesAPI(event)
})
