export default [
  {
    path: '/academy',
    name: 'academy',
    component: () => import('@/views/academy/index.vue'),
    meta: {
      pageTitle: 'Academy',
      breadcrumb: [
        {
          text: 'Academy',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
]
