export default [
  {
    path: '/',
    name: 'dashboard',
    props: route => ({ id: route.query.groupId }),
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          // text: 'Dashboard',
          active: true,
          icon: 'HomeIcon',
        },
      ],
      requireAuth: true,
    },
  },
]
