import Vue from 'vue'
import { showToast } from '@/common/global/functions'

export default {
  namespaced: true,
  state: {
    singleDevice: {},
    allGroups: [],
    devices: [],
    analytics: {},
    dataset: [],
    labels: [],
    max: 5,
    stepSize: 5,
    originalDeviceList: [],
    deviceList: [],
    singleScreenData: {},
    devicesTotalRevenue: 0,
    totalRevenue: 0,
    originalSingleScreenArray: [],
    singleScreenArray: [],
    total: 0,
    offset: 1,
    limit: 10,
    parameters: {},
  },
  mutations: {
    SET_ALL_GROUPS(state, payload) {
      state.allGroups = [...payload]
    },
    SET_DEVICES(state, payload) {
      state.devices = [...payload]
    },
    SET_DEVICE(state, payload) {
      state.singleDevice = { ...payload }
    },
    SET_EMPTY_ANALYTICS(state, payload) {
      state.dataset = []
      state.labels = []
      state.stepSize = 0
      state.max = 0
      state.analytics = { ...payload }
    },
    RESET_DATA(state) {
      state.analytics = {}
      state.dataset = []
      state.labels = []
      state.max = 5
      state.stepSize = 5
      state.originalDeviceList = []
      state.deviceList = []
    },
    RESET_SCREEN_DATA(state) {
      state.totalRevenue = 0
      state.originalSingleScreenArray = []
      state.singleScreenArray = []
    },
    SET_ANALYTICS(state, payload) {
      state.analytics = { ...payload }
      state.dataset = payload.data.dataSet
      state.labels = payload.data.labels
      state.devicesTotalRevenue = payload.data.total_revenue
      const maxValue = Math.max(...payload.data.dataSet)

      if (maxValue >= 500 && maxValue <= 1000) {
        state.max = maxValue + 100
        state.stepSize = 100
      } else if (maxValue >= 1000 && maxValue <= 3000) {
        state.stepSize = Math.round(maxValue / 4)
        state.max = maxValue + state.stepSize
      } else if (maxValue >= 3001 && maxValue <= 5000) {
        state.stepSize = Math.round(maxValue / 4)
        state.max = maxValue + state.stepSize
      } else {
        state.max = 500
        state.stepSize = 100
      }
    },
    // eslint-disable-next-line no-unused-vars
    SET_EMPTY_DEVICE_LIST(state, payload) {
      state.deviceList = []
    },
    SET_ORIGINAL_DEVICE_LIST(state, payload) {
      state.originalDeviceList = [...payload.devices]
      state.total = payload.devices.length
    },
    SET_DEVICE_LIST(state, payload) {
      state.deviceList = [...payload]
    },

    SET_ORIGINAL_SCREEN_DATA(state, payload) {
      state.singleScreenData = { ...payload }
      state.totalRevenue = state.singleScreenData.data.device_total_revenue
      state.originalSingleScreenArray = state.singleScreenData.data.device
      state.total = state.singleScreenData.data.device.length
      state.offset = 1
      state.limit = 10
    },
    SET_SCREEN_DATA(state, payload) {
      state.singleScreenArray = payload
    },
    SET_PAYLOAD(state, payload) {
      // eslint-disable-next-line no-param-reassign
      delete payload?.group_ids
      // eslint-disable-next-line no-param-reassign
      delete payload?.device_ids
      state.parameters = { ...payload }
    },
  },
  actions: {
    async allGroups({ commit, rootGetters }) {
      if (rootGetters['user/getUser'].user_has_role[0].role.role === 'admin') {
        try {
          const { data: { data } } = await Vue.prototype.$axios.get('admin/user-has-group?sortDir=asc')
          commit('SET_ALL_GROUPS', data.results)
        } catch (e) {
          console.error(e)
          throw new Error(e)
        }
      } else {
        try {
          const { data: { data } } = await Vue.prototype.$axios.get('user-has-group/get-user-groups')
          commit('SET_ALL_GROUPS', data)
        } catch (e) {
          console.error(e)
          throw new Error(e)
        }
      }
    },
    async getDevices({ commit, rootGetters }, { userHasGroupId }) {
      if (rootGetters['user/getUser'].user_has_role[0].role.role === 'admin') {
        try {
          const { data: { data } } = await Vue.prototype.$axios.get(`admin/device/${userHasGroupId}`)
          commit('SET_DEVICES', data.results)
        } catch (e) {
          console.error(e)
          throw new Error(e)
        }
      } else {
        try {
          const { data: { data } } = await Vue.prototype.$axios.get('user/device', {
            headers: {
              userHasGroupId,
            },
          })
          commit('SET_DEVICES', data.results)
        } catch (e) {
          console.error(e)
          throw new Error(e)
        }
      }
    },
    setPaginatedDeviceData({ state, commit, getters }, { offset, limit }) {
      const array = getters.getOriginalDeviceList
      state.offset = offset
      state.limit = limit
      const start = (offset - 1) * limit
      const end = start + limit
      const updatedArray = array.slice(start, end)
      commit('SET_DEVICE_LIST', updatedArray)
    },
    async getAnalytics({ commit }, payload) {
      try {
        const response = await Vue.prototype.$axios.post('ssp/ads/analytics', payload)
        const { data } = response
        if (data.statusCode === 200) {
          if (data.data.dataSet.length === 0 && data.data.labels.length === 0) {
            commit('SET_EMPTY_ANALYTICS', data.data)
          } else {
            commit('SET_ANALYTICS', data)
          }
        }
        return data
      } catch (e) {
        console.error(e)
        showToast('Data', e.response?.data?.message.toString() || 'An error occurred', 'danger')
        throw new Error(e)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async getDeviceLists(context, payload) {
      try {
        const response = await Vue.prototype.$axios.post('ssp/ads/list', payload)
        const { data } = response
        if (response.data.statusCode === 200) {
          if (response.data.data.devices.length === 0) {
            context.commit('SET_EMPTY_DEVICE_LIST', [])
          } else {
            context.commit('SET_ORIGINAL_DEVICE_LIST', data.data)
            context.dispatch('setPaginatedDeviceData', { offset: context.state.offset, limit: context.state.limit })
          }
        }
        return data
      } catch (e) {
        console.error(e)
        showToast('Data', e.response?.data?.message.toString() || 'An error occurred', 'danger')
        throw e
      }
    },
    async getAnalyticsAndDeviceLists({ commit, dispatch }, payload) {
      commit('SET_PAYLOAD', { ...payload })
      commit('RESET_DATA')
      try {
        const analyticsPromise = await dispatch('getAnalytics', { ...payload })
        const deviceListsPromise = await dispatch('getDeviceLists', { ...payload })
        const analyticsResponse = await analyticsPromise
        const deviceListsResponse = await deviceListsPromise
        return { analytics: analyticsResponse, deviceLists: deviceListsResponse }
      } catch (error) {
        console.error('Error:', error)
        showToast('Error', error.response.data.message, 'danger')
        throw error
      }
    },
    async getScreenData(context, { deviceId }) {
      const payload = context.state.parameters
      try {
        const response = await Vue.prototype.$axios.post('ssp/ads/device/', {
          device_ids: deviceId,
          ...payload,
        })
        if (response.data.statusCode === 200) {
          const { data } = response
          context.commit('SET_ORIGINAL_SCREEN_DATA', data)
          context.dispatch('setPaginatedScreenData', { offset: context.state.offset, limit: context.state.limit })
          return data
        }
        throw new Error(response.data.message.toString())
      } catch (e) {
        console.error(e)
        throw e
      }
    },
    setPaginatedScreenData({ state, commit, getters }, { offset, limit }) {
      const array = getters.getOrginalScreenArray
      state.offset = offset
      state.limit = limit
      const start = (offset - 1) * limit
      const end = start + limit
      const updatedArray = array.slice(start, end)
      commit('SET_SCREEN_DATA', updatedArray)
    },
    async adminDeviceData({ commit }, { userId, deviceId }) {
      try {
        const response = await Vue.prototype.$axios.get(`admin/device/find/${deviceId}`, {
          headers: {
            userHasGroupId: userId,
          },
        })
        if (response.data.statusCode === 200) {
          const { data } = response.data
          commit('SET_DEVICE', data)
          return true
        }
      } catch (e) {
        console.log(e)
        throw new Error('No Venue found against this ID')
      }
      return false
    },
    async userDeviceData({ commit }, { userId, id }) {
      try {
        const response = await Vue.prototype.$axios.get(`user/device/find/${id}`, {
          headers: {
            userHasGroupId: userId,
          },
        })
        if (response.data.statusCode === 200) {
          const { data: { data } } = response || {}
          commit('SET_DEVICE', data)
          return true
        }
      } catch (e) {
        console.log(e)
        throw new Error('No Venue found against this ID')
      }
      return false
    },
  },
  getters: {
    getDevice: state => state.singleDevice,
    getAllGroups: state => state.allGroups,
    getDevices: state => state.devices,
    getAnalytics: state => state.analytics,
    getDataSets: state => state.dataset,
    getLabels: state => state.labels,
    getMaxValue: state => state.max,
    getStepSize: state => state.stepSize,
    getDeviceList: state => state.deviceList,
    getOriginalDeviceList: state => state.originalDeviceList,
    getAdsTotalRevenue: state => state.totalRevenue,
    getDevicesTotalRevenue: state => state.devicesTotalRevenue,
    getAdsData: state => state.singleScreenArray,
    getOrginalScreenArray: state => state.originalSingleScreenArray,
    getOffset: state => state.offset,
    getTotal: state => state.total,
    getLimit: state => state.limit,
  },
}
