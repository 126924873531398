import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    bannerAd: {
    },
  },
  mutations: {
    SET_BANNER_AD(state, payload) {
      state.bannerAd = { ...payload }
    },
  },

  actions: {
    // eslint-disable-next-line no-unused-vars
    async deleteBannerAds({ commit }, { userHasGroupId, bannerId }) {
      try {
        const response = await Vue.prototype.$axios.delete(`admin/banner-campaign/${bannerId}`, {
          headers: {
            userHasGroupId,
          },
        })
        return response
      } catch (e) {
        throw new Error(e)
      }
    },
    async editBannerAdStateDisable({ commit }, { userHasGroupId, bannerId }) {
      try {
        const response = await Vue.prototype.$axios.put(`admin/banner-campaign/disable-banner-campaign/${bannerId}`,
          { headers: { userHasGroupId } })
        if (response.data.statusCode === 200) {
          commit('SET_BANNER_AD', response.data.data)
        }
        return response
      } catch (e) {
        throw new Error(e)
      }
    },
    async editBannerAdStateEnable({ commit }, { userHasGroupId, bannerId }) {
      try {
        const response = await Vue.prototype.$axios.put(`admin/banner-campaign/activate-banner-campaign/${bannerId}`,
          { headers: { userHasGroupId } })
        if (response.data.statusCode === 200) {
          commit('SET_BANNER_AD', response.data.data)
        }
        return response
      } catch (e) {
        throw new Error(e)
      }
    },
    async getSingleBannerAd({ commit }, { userHasGroupId, bannerId }) {
      try {
        const response = await Vue.prototype.$axios.get(`admin/banner-campaign/find/${bannerId}`, {
          headers: {
            userHasGroupId,
          },
        })
        if (response.data.statusCode === 200) {
          commit('SET_BANNER_AD', response.data.data)
        }
        return response
      } catch (e) {
        throw new Error(e)
      }
    },
  },
  getters: {
    getBannerAd: state => state.bannerAd,
  },
}
